import * as React from 'react'
import { Link } from 'gatsby'
import IndexLayout from '../layouts'

export default function Beratung() {
  return (
    <IndexLayout>
      <div>
        <h1 className="sectionTitle">Beratung</h1>
        <p>„Beratung ist, deinen Gefühlen den Platz zu geben, den sie brauchen, um zu heilen."</p>
        <p>
          Die Seele sucht immer nach Ausdruck ihrer selbst. Wenn du auf sie hörst, dann führst du ein wertvolles, freies und authentisches
          Leben. Ist dieser Ausdruck gehemmt oder nicht möglich, kommt es zu Leid, Schmerz, Krankheiten oder Blockaden. Die Bedürfnisse
          führen nun ein Schattendasein mit selbst auferlegten Grenzen. Die Seele klopft an, flüstert uns zu und sucht nach einem Ausweg!
        </p>
        <p>Beratung bedeutet für mich:</p>
        <ul>
          <li>gemeinsam mit dir, deiner außergewöhnlichen Seele eine kraftvolle Stimme zu geben</li>
          <li>gemeinsam mit dir, deinen Schatten (dem Unliebsamen in dir) mit Licht zu begegnen</li>
          <li>gemeinsam mit dir, deine Grenzen zu sprengen</li>
          <li>gemeinsam mit dir, aus deinen Krisen Ressourcen zu schöpfen</li>
          <li>gemeinsam mit dir, deine Verletzungen in Kraft zu verwandeln</li>
          <li>gemeinsam mit dir, hinter der Krankheit die Blockade zu erkennen</li>
          <li>gemeinsam mit dir, dich wieder mit deinem „Inneren Kind“ zu verbinden</li>
          <li>gemeinsam mit dir, immer wiederkehrende Muster zu entdecken, und sie durch neue Sichtweisen zu ersetzen</li>
          <li>gemeinsam mit dir, deinen Körper und deine Seele zu Teamplayern zu machen</li>
        </ul>
        <p>Was bringe ich in meine Beratung ein:</p>
        <ul>
          <li>Einfühlungsvermögen und die Ausdauer einer Pflegemutter</li>
          <li>pragmatische und kreative Ansätze einer Architektin</li>
          <li>Flexibilität und Ruhe einer Yoga Praktizierenden</li>
        </ul>
        <p>Schreiben:</p>
        <p>
          ist eine Möglichkeit, sich selber phantasievoll und heilsam zu begegnen. Du kannst deinen Empfindungen, deiner Kreativität, deiner
          Freude und auch deinen Verletzungen respektvoll Raum geben.
        </p>
        <p>Austausch:</p>
        <p>€ 70,- pro Beratungsstunde</p>
        <p>Kurse / Workshops / Aufstellungen</p>
        <p>
          Infos zu Veranstaltungen findest du unter{' '}
          <Link to="/aktuelles" onClick={() => this.props.onLinkClicked('Aktuelles')} style={{ textDecoration: 'underline' }}>
            Aktuelles
          </Link>
        </p>
      </div>
    </IndexLayout>
  )
}
